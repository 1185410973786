<template>
<!-- {{store.plan_}}
{{store.planData}} -->
    <div class="row gx-2 services-form m-auto d-flex justify-content-around">
        <h2 class="fw-bold  text-center">Pricing That Fits Every Business</h2>
        <div class="col-5 mb-4 mt-5 sub-box text-center">
            <h5 class="fw-bold fs-5">Subscription</h5>
            <div class="text-center rounded">
                <div class="m-4 rounded">
                    <p>Here is what you'll pay</p>
                    <span class="fs-1 fw-bold">${{planAmount}} </span>
                    <span class="text-muted"> {{planDuration}}</span>
                </div>
                <p>For anything worth having pay the price</p>
                <div class="row independent-box mt-4">
                    <!--   plan.no_of_employees < store.planData.total_employees ? 'disabled-plan':''  -->
                    <div v-for="plan in store.plans" :key="plan.id" v-on:click="choosePlan(plan)"
                        :class="[$route.params.action === 'upgrade' ? plan.no_of_employees <= store.planData.plan.no_of_employees ? 'disabled-plan':'' : plan.no_of_employees < store.planData.total_employees ? 'disabled-plan':'', store.plan_.plan.id == plan.id ? 'active':'']"
                        class="col change-bg p-2">{{plan.title}}</div>
                </div>
                <p class="mt-2 text-start" v-if="$route.params.action !== 'upgrade'">* If you want to downgrade please delete extra employees. </p>
                <p class="mt-2 text-start" v-if="$route.params.action === 'upgrade'">Plan Expiry Date is: <strong>{{planExpiryDate}}</strong></p>
            </div>
        </div>
        <div class="col-5 mb-4 mt-5 sub-box">
            <div class="cardInfo">
                <div class="row">
                    <div class="col-12">
                        <h5 class="fw-bold">Payment Information</h5>
                    </div>
                    <StripeCard :cardData="updateCard"  />
                </div>
            </div>
        </div>
        <div class="col-12 text-center">
            <button class="next action-button mt-2" v-on:click="updatePlan">Pay Now</button>
        </div>
    </div>
</template>

<script>
import StripeCard from '@/components/common/StripeCard.vue'
import {useSubscriptionStore} from '@/stores/subscription'
import moment from 'moment'
import { useToast } from "vue-toastification";

export default {
    setup(){
        const toast = useToast();
        const store = useSubscriptionStore()
        return {store,toast}
    },
    components:{
        StripeCard
    },
    mounted() {
        this.store.plan_.business_id = this.$storage.getStorageSync('business').active_business.id
        this.store.plan_.action = this.$route.params.action
        this.$nextTick(async () => {
            await this.store.getPlansList();
            await this.store.planDetail(this.$storage.getStorageSync('business').active_business.id);

            if (this.$route.params.action === 'upgrade' && this.store.planData.subscription_plan_id != null && this.store.planData.subscription_plan_id != '') {
                this.store.plan_.plan = this.store.planData.plan
            }else{
                let thiss = this
                for (const plan of this.store.plans) {
                    if (plan.no_of_employees >= thiss.store.planData.total_employees) {
                        thiss.store.plan_.plan = plan;
                        break;
                    }
                }
            }
            // if (this.store.planData.user_settings) {
            //     this.store.plan_.card = this.store.planData.user_settings.card_for_auto_renew
            // }
        });
    },
    methods: {
        choosePlan(plan){
            if (this.$route.params.action === 'upgrade') {
                if (plan.no_of_employees > this.store.planData.plan.no_of_employees) {
                    this.store.plan_.plan = plan
                }
            }else{
                if (plan.no_of_employees >= this.store.planData.total_employees) {
                    this.store.plan_.plan = plan
                }
            }
        },
        updateCard(data){
            this.store.plan_.card = data
        },
        async updatePlan(){
            if (this.store.plan_.card === '' || this.store.plan_.card === null || this.store.plan_.card === undefined) {
                this.toast.error('Please select payment card.');
                return;
            }
            if (this.$route.params.action === 'upgrade' && this.store.plan_.plan.no_of_employees <= this.store.planData.plan.no_of_employees) {
                this.toast.error('Please choose upgraded plan.');
                return;
            }

            const resp = await this.store.updatePlan()
            //console.log(resp)
            if (resp.status === 'success') {
                this.toast.success(resp.message);
                this.store.clearPlan()
                this.$router.push({ name: 'Settings'})
            }else{
                this.toast.error(resp.message);
            }
        }
    },
    computed:{
        planExpiryDate(){
            return moment(this.store.planData.plan_expiry_date).format('MMM DD, YYYY')
        },
        planAmount(){
            if (this.$route.params.action === 'upgrade' && this.store.plan_.plan.id != this.store.planData.plan.id) {
                return this.store.plan_.plan.amount - this.store.planData.plan.amount
            }else{
                return this.store.plan_.plan.amount
            }
        },
        planDuration(){
            if (this.$route.params.action === 'upgrade' && this.store.plan_.plan.id != this.store.planData.plan.id) {
                return ' + $'+this.store.planData.plan.amount+' per month'
            }else{
                return ' per month'
            }
        }
    }
}
</script>
<style scoped>
    .select-mark {
        font-size: 30px;
        width: 40px;
        height: 40px;
        position: absolute;
        top: 0;
        right: 2px;
    }
    .select-mark svg{
        border-radius: 50%;
        background-color: white;
        color: #6e317a;
    }
    .sub-box{
        padding: 30px 20px;
        border-radius: 15px;
        background-color: #fff;
        box-shadow: 0px 3px 13px 0 rgb(0 0 0 / 10%);
    }
    .sub-box.active{
        background-color: #f8f9fa;
        position: relative;
        border: 1px solid #6e317a;
    }
    .independent-box{
        padding: 12px 8px;
        align-items: center;
        margin: auto;
        border-radius: 12px;
        background-color: #f8f8f8;

    }
    .sub-box.active .independent-box{
        background-color: #fff;
    }
    .independent-box div{
        cursor: pointer;
        border-radius: 5px;
    }
    .independent-box div:hover{
        background-color:#c89aca3d;
    }
    .independent-box div.disabled-plan{
        background-color: #f8f8f8;
        cursor: not-allowed;
        color: #c9c9c9;
    }
    .independent-box div.active{
        background-color: #6e317a;
        color: white;
    }
    .PayAsYouEarn-stat{
        color: #999;
    }
    .action-button {
        background: #6e317a;
        border: 1px solid #6e317a;
        color: #fff;
        box-shadow: 0 4px 8px rgb(110 49 122 / 36%);
        font-family: 'Roboto', sans-serif;
        font-size: 13px;
        font-weight: 400;
        width: auto;
        padding: 10px 40px;
        display: inline-block;
        border-radius: 5px;
        transition: all 0.35s ease-in-out;
    }
</style>