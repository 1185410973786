<template>
  <AppContainer>
    <div class="setting-area">
      <SubscriptionPlans />
    </div>
  </AppContainer>
</template>

<script>
import AppContainer from "@/components/containers/DashboardContainer.vue";
import SubscriptionPlans from '@/components/dashboard/settings/SubscriptionPlans.vue';
export default {
  components: {
    AppContainer,
    SubscriptionPlans,
  },
};
</script>